import React from "react";
import HeadingWithSub from "../components/HeadingWithSub";
import LinkToTour from "../components/LinkToTour";
import styled from "@emotion/styled";
import { graphql } from "gatsby";
import ParentRouteHandler from "../components/ParentRouteHandler";

const GuidesMaps = ({ data, location }) => {
  const maps = data.maps.edges.map(({ node }) => {
    return {
      label: node.title,
      text:
        node.relationships.field_mobile_map_images.length !== 0
          ? "View"
          : "Coming Soon",
      link: `/map?lang=${node.field_mobile_map_language_code}`,
    };
  });

  let visitorPdfs = [];

  data.pdfs.edges.map(({ node }) => {
    let item;
    if (node.relationships.field_pdf_asset) {
      item = node.relationships.field_pdf_asset;
    }
    if (node.field_pdf_category === "visitor") {
      visitorPdfs.push({
        id: node.id,
        label: node.title,
        text: item ? "View" : "Coming Soon",
        link: item ? item.localFile.publicURL : "",
        newTab: item ? true : false,
      });
    }
  });

  const Container = styled.div`
    h2.small {
      font-size: 30px;
    }

    .intro {
      font-size: 16px;
      margin-bottom: 10px;
      margin-top: 16px;
    }

    .guides {
      margin-bottom: 42px;
    }

    .map {
      display: block;
      margin-top: 30px;
    }
  `;

  const isNavigatedFromNavMenu = location.search.includes("from-nav=1");

  return (
    <Container>
      {isNavigatedFromNavMenu && <ParentRouteHandler title={"back"} />}

      <div className="guides">
        <HeadingWithSub main={"Visitor Guides"} h2={true} />

        <p className="intro">
          Learn everything you need to know for your visit today, including new
          visitor policies to keep The Broad a place for reflection,
          reconnection, and inspiration.
        </p>

        {visitorPdfs.map((guide) => (
          <LinkToTour
            key={guide.id}
            title={guide.label}
            subtitle={guide.text}
            link={guide.link}
            isDownload={false}
            displayImage={false}
            newTab={guide.newTab}
            isPdf={true}
            originPath="/guides-maps"
            origin="Visitor Guides & Maps"
            category="Visitor Guide"
          />
        ))}
      </div>

      <div className="maps">
        <HeadingWithSub main={"Maps"} h2={true} />
        {maps &&
          maps.map((item) => {
            return (
              <LinkToTour
                key={item.label}
                title={item.label}
                subtitle={item.text}
                link={item.link}
                displayImage={false}
              />
            );
          })}
      </div>
    </Container>
  );
};

export default GuidesMaps;

export const query = graphql`
  {
    maps: allNodeMobileMap(
      sort: { fields: field_mobile_map_language_order, order: ASC }
    ) {
      edges {
        node {
          ...MobileMapQuery
        }
      }
    }
    pdfs: allNodeMobilePdf {
      edges {
        node {
          ...MobilePDFQuery
        }
      }
    }
  }
`;
